import { inject }                       from 'aurelia-framework';
import { PLATFORM }                     from 'aurelia-pal';
import { BaseFormViewModel }            from 'base-form-view-model';
import { FormSchema }                   from 'modules/aggregates/lab/form-schema';
import { AggregatesLabTestsRepository } from 'modules/aggregates/lab/services/repository';
import { AggControlType }               from 'modules/aggregates/models/control-type';
import { Sector }                       from 'modules/management/models/sector';
import { AppContainer }                 from 'resources/services/app-container';

@inject(AppContainer, AggregatesLabTestsRepository, AggControlType, FormSchema)
export class EditAggregatesLabTest extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    routePrefix = 'aggregates.lab';
    permissionPrefix;

    alert            = {};
    model            = {};
    activeControlTab = false;
    tabs             = {};

    create = false;

    constructor(appContainer, repository, controlType, formSchema) {
        super(appContainer);

        this.repository  = repository;
        this.controlType = controlType;
        this.formSchema  = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/aggregates/lab/form.html');
    }

    /**
     * Handles canActivate event
     */
    async canActivate(params) {
        this.category = params.category;
        this.repository.setCategory(this.category);

        this.formId           = `aggregates-${this.category}-lab-edition-form`;
        this.permissionPrefix = `aggregates.${this.category}.lab`;
        this.routePrefix      = `aggregates.${this.category}.lab`;
        this.sectorId         = (this.category === 'not-treated')
            ? Sector.AGGREGATES_NT_LAB
            : Sector.AGGREGATES_T_LAB;

        this.backRoute       = `${this.routePrefix}.index`;
        this.backRouteParams = params;

        return this.redirectToSelectLotIntervention(`${this.routePrefix}.edit`, params) && super.can([
            this.permissionPrefix + '.manage',
            this.permissionPrefix + '.edit',
        ]);
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        if (this.appContainer.authenticatedUser.isLotClosed || this.model.is_validated) {
            return this.redirectToRoute({
                name:   `${this.routePrefix}.view`,
                params: params || {},
            });
        }

        // Get schema for each tab
        this.tabs.selectBulletins          = await this.formSchema.selectBulletinsSchema.schema(this);
        this.tabs.general                  = this.formSchema.generalSchema.schema(this);
        this.tabs.general.lotInfoSchema    = this.formSchema.lotInfoSchema(this);
        this.tabs.workControl              = await this.formSchema.workControlSchema.schema(this);
        this.tabs.materialCharacterization = await this.formSchema.materialCharacterizationSchema.schema(this);
        this.tabs.additionalInfo           = this.formSchema.additionalInfoSchema.schema(this);
        this.globalSchema                  = this.formSchema.globalSchema(this);

        this.updateControlTabs(this.model.control_type_id);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.update(this.sectorId, this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, {
                    name:   this.backRoute,
                    params: this.backRouteParams,
                });
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }

    /**
     * Update Control Tabs
     * @param controlTypeId
     */
    updateControlTabs(controlTypeId) {
        this.activeControlTab = controlTypeId;
    }
}
